import React, { useEffect, useState, useRef } from "react"
import _ from "lodash"
import { Box, Button, Flex, Text, Image } from "rebass"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import Layout from "../../components/_layout"
import Container from "../../components/_layout/container"
import SEO from "../../components/seo"

const HeartIcon = () => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g>
      <g>
        <path
          fill="#fd6f3b"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        />
      </g>

      <g>
        <path
          fill="#fff"
          d="M17.064 12.592l-4.537 4.684a.735.735 0 0 1-1.037.025.752.752 0 0 1-.025-.025l-4.533-4.684c-1.316-1.362-1.238-3.618.234-4.873 1.284-1.095 3.195-.899 4.373.316l.463.475.464-.475c1.177-1.215 3.088-1.411 4.372-.316 1.469 1.255 1.547 3.51.226 4.873z"
        />
      </g>
    </g>
  </motion.svg>
)

const StepImage = ({ i, color = "#e0e0e0" }) => {
  const [ref, isInView] = useInView({
    threshold: 0.1,
  })

  return (
    <motion.svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="8"
      viewBox="0 0 52 8"
      initial={{ opacity: 0 }}
      animate={
        !isInView
          ? { opacity: 0 }
          : {
              opacity: 1,
            }
      }
      transition={{ duration: 0.2 * (i + 1) }}
    >
      <g>
        <g>
          <motion.path fill={color} d="M52 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0z" />
        </g>

        <g>
          <motion.path fill={color} d="M0 3h40v2H0z" />
        </g>
      </g>
    </motion.svg>
  )
}

const ServiceLeadingSection = ({
  i,
  headline,
  subtext,
  content = <></>,
  mockupImage,
  setMockupImage,
}) => {
  const wrapperRef = useRef()

  const [ref, isInView] = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    const onScroll = () => {
      const iterations = _.times(3)
      const { offsetHeight } = wrapperRef.current

      const breakpoints = _.map(_.clone(iterations), v => [
        wrapperRef.current.getBoundingClientRect().top +
          (offsetHeight / iterations.length) * v -
          offsetHeight / 2,

        wrapperRef.current.getBoundingClientRect().top +
          ((offsetHeight / iterations.length) * v +
            offsetHeight / iterations.length) -
          offsetHeight / 2,
      ])

      _.forEach(breakpoints, (breakpoint, breakpointIndex) => {
        if (
          breakpoint[0] <= 0 &&
          breakpoint[1] >= 0 &&
          mockupImage !== `_${breakpointIndex}`
        ) {
          setMockupImage(`_${breakpointIndex}`)
        }
      })
    }

    document.addEventListener("scroll", onScroll)

    return () => document.removeEventListener("scroll", onScroll)
  }, [mockupImage, setMockupImage])

  return (
    <div ref={wrapperRef}>
      <Container
        backgroundColor={i % 2 === 0 ? "rgba(91, 83, 251, 0.04)" : "#fff"}
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: "center",
          height: ["auto", "auto", "100vh"],
        }}
        px={[2, 5]}
      >
        <Flex width="100%" px={[5, 0]}>
          <Box sx={{ flexShrink: 0 }} width={[0, 0, "40%"]} />

          <Box sx={{ flexShrink: 0 }} width={["100%", "100%", "40%"]}>
            <Box
              ref={ref}
              sx={{
                position: "relative",
                display: "inline-flex",
                transform: ["scale(0.8)", "none", "none", "none"],
                transformOrigin: "left top",
              }}
            >
              <motion.div
                initial={{ left: "calc(0% - 16px)" }}
                animate={
                  !isInView
                    ? { left: "calc(0% - 16px)" }
                    : {
                        left: `calc(${
                          (100 * (i + 1)) / leadingSections.length
                        }% - 16px)`,
                      }
                }
                transition={{
                  duration: 1,
                  delay: 0.3,
                }}
                style={{
                  position: "absolute",
                  top: "2px",
                }}
              >
                <HeartIcon />
              </motion.div>

              <Flex>
                {leadingSections.map((section, sectionIndex) => (
                  <Box key={sectionIndex} ml="4px">
                    <StepImage
                      i={sectionIndex}
                      color={i >= sectionIndex ? "#fd6f3b" : "#e0e0e0"}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>

            <Text
              color="dark"
              lineHeight={["50px"]}
              mt={[6]}
              fontSize={["30px", "40px"]}
              fontWeight="700"
            >
              {headline}
            </Text>

            <Text mt={3} fontSize={"20px"} color="#828282">
              {subtext}
            </Text>

            {content}
          </Box>
        </Flex>

        <Box
          my={8}
          sx={{
            display: ["block", "block", "none"],
          }}
        >
          <Image
            sx={{ maxWidth: "240px" }}
            src="https://i.imgur.com/6ywS25M.png"
            alt=""
          />
        </Box>
      </Container>
    </div>
  )
}

const leadingSections = [
  {
    headline: "We acquire traffic on social media",
    subtext:
      "Thanks to our expertise we are able to cherry-pick the right target in a 2 billions users pool",
  },
  {
    headline: "We funnel users to our own engagement tools",
    subtext:
      "As soon as our users are hooked by our ads, they are brought to one of our 6 engagement tools",
  },
  {
    headline: "We activate them",
    subtext:
      "We got their attention, and we can't fail them. Our data-driven content team knows the words we have to leverage in order to hook our target",
  },
  {
    headline: "We engage them",
    subtext:
      "We increase users' intent by allowing them to tell us their specific need",
  },
  {
    headline: "We capture leads",
    subtext:
      "They're ready. They want to know what your brand can do for them. It's time to collect their data",
  },
  {
    headline: "We send them to your CRM",
    subtext:
      "The deal is closed. Now it's your turn; we're sure you will be your target best choice",
  },
]

const ParallaxPhone = ({ mockupImage, shouldHide }) => {
  const ref = useRef()

  return (
    <motion.div
      initial={{ opacity: 1, zIndex: 1 }}
      animate={{
        opacity: shouldHide ? 0 : 1,
        ...(shouldHide ? { transitionEnd: { zIndex: -1 } } : { zIndex: 1 }),
      }}
    >
      {/* {["0_0", "0_1", "0_2"].map(val => ( */}
      <img
        // key={val}
        ref={ref}
        // src={require(`../../assets/images/mockup_frame_${val}.png`)}
        src="https://i.imgur.com/6ywS25M.png"
        alt=""
        style={{
          position: "fixed",
          top: "230px",
          // zIndex: mockupImage === val ? 2 : 1,
          // opacity: mockupImage === val ? 1 : 0,
          width: 300,
        }}
      />
      {/* ))} */}
    </motion.div>
  )
}

const Services = () => {
  const chatbot = useRef()
  const stopParallaxBlock = useRef()
  const [mockupImage, setMockupImage] = useState("0_0")
  const [shouldStopParallax, setShouldStopParallax] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setShouldStopParallax(
        stopParallaxBlock.current.getBoundingClientRect().top -
          window.innerHeight <
          0
      )
    }

    document.addEventListener("scroll", onScroll)

    return () => document.removeEventListener("scroll", onScroll)
  }, [setShouldStopParallax])

  return (
    <Layout forceOpaqueHeader>
      <SEO
        title="The services we offer"
        description="We have a long-standing experience in the world of advertising, business and lead generation."
      />

      <Container
        backgroundColor="orange"
        py={["110px", "180px"]}
        px={5}
        sx={{ mb: [7, 0, 0] }}
      >
        <Flex mt={[4, 7]}>
          <Box
            sx={{ position: "relative", flexShrink: 0 }}
            width={[0, 0, "40%"]}
          >
            <Box sx={{ display: ["none", "none", "block"] }}>
              <ParallaxPhone
                mockupImage={mockupImage}
                shouldHide={shouldStopParallax}
              />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Text
              mt={[0, 6]}
              color="white"
              fontSize={["34px", "46px"]}
              fontWeight="700"
            >
              We generate high-intent
              <br /> leads for businesses with a<br /> pay-per-lead model.
            </Text>

            <Button
              onClick={() =>
                chatbot.current.scrollIntoView({ behavior: "smooth" })
              }
              sx={{
                mt: 8,
                py: 4,
                px: 8,
                fontSize: [1],
                bg: "primary",
                width: ["100%", "auto", "auto"],
              }}
            >
              Contact us
            </Button>
          </Box>
        </Flex>

        <Box
          mt={8}
          mb={"-140px"}
          sx={{
            display: ["block", "block", "none"],
            textAlign: "center",
          }}
        >
          <Image
            sx={{ maxWidth: "240px" }}
            src="https://i.imgur.com/6ywS25M.png"
            alt=""
          />
        </Box>
      </Container>

      {leadingSections.map((section, i) => (
        <ServiceLeadingSection
          key={i}
          i={i}
          {...section}
          mockupImage={mockupImage}
          setMockupImage={setMockupImage}
        />
      ))}

      <div ref={stopParallaxBlock} />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Container backgroundColor="#fd6f3b" py={["180px"]} px={5}>
          <Flex alignItems="center" flexDirection={["column", "row"]}>
            <Box
              width={["100%", "50%"]}
              flexShrink={0}
              sx={{
                bg: "white",
                borderRadius: "8px",
                p: 7,
              }}
            >
              <Text
                fontSize={["26px", "34px"]}
                fontWeight="700"
                lineHeight="45px"
              >
                As soon as we capture a lead, we automatically update our
                historical data.
              </Text>

              <Text mt={7} fontSize="20px" color="#828282" fontWeight="500">
                Our technology and approach makes it possible for our model to
                optimize itself, so that we're able to deliver huge scale
                volumes while we keep increasing our leads quality.
              </Text>
            </Box>

            <Box width={["100%", "50%"]} flexShrink={0} mt={[8, 0]} px={[4, 0]}>
              <Flex
                sx={{
                  ml: [0, 9],
                  flexWrap: "wrap",
                }}
              >
                {[
                  {
                    image: "audience",
                    text: "Automatic audience exclusion",
                  },
                  {
                    image: "bi",
                    text: "BI and in-depth action oriented reports",
                  },
                  {
                    image: "behavior",
                    text: "Behavioral custom audiences",
                  },
                  {
                    image: "lookalike",
                    text: "Audience targeting optimization",
                  },
                ].map((val, i) => (
                  <Box
                    key={i}
                    width={["100%", "50%", "50%"]}
                    mb={[6, i < 2 ? 7 : 0]}
                    pr={3}
                    sx={{
                      textAlign: ["center", "left", "left"],
                    }}
                  >
                    <Image
                      src={require(`../../assets/images/services_${val.image}.png`)}
                      alt=""
                    />

                    <Text mt={3} color="white" fontSize="19px" fontWeight="700">
                      {val.text}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>

      <Container backgroundColor="#fd6f3b" py={["80px"]} px={5}>
        <Text
          color="white"
          textAlign="center"
          fontSize={["30px", "40px"]}
          fontWeight="700"
        >
          Leads on demand...
        </Text>

        <Flex mt={8} mb={9} justifyContent="center" sx={{ flexWrap: "wrap" }}>
          {[
            {
              image: "lead_1",
              text: "High-converting unique leads at prices with no surprises",
            },
            {
              image: "lead_2",
              text: "Massive volume at a steady high quality",
            },
          ].map((val, i) => (
            <Box
              key={i}
              flexShrink={0}
              width={["100%", "350px", "400px"]}
              sx={{
                mt: [i === 0 ? 0 : 7, 0],
                px: [4, 5, 6],
                textAlign: ["center"],
              }}
            >
              <Image
                src={require(`../../assets/images/services_${val.image}.png`)}
                alt=""
              />

              <Text mt={3} color="white" fontSize="19px" fontWeight="700">
                {val.text}
              </Text>
            </Box>
          ))}
        </Flex>

        <Text
          color="white"
          textAlign="center"
          fontSize={["30px", "40px"]}
          fontWeight="700"
        >
          ...in the snap of a finger
        </Text>

        <Flex
          flexDirection={["column", "row", "row"]}
          bg="white"
          mt={8}
          mb={-10}
          p={7}
          sx={{
            borderRadius: "4px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          {[
            {
              image: "users-class_1",
              text:
                "We sit side by side with your marketing and sales departments",
            },
            {
              image: "calendar-check_1",
              text:
                "We set things up and we are ready to go in a week or less, no technical involvement on your side",
            },
            {
              image: "badge-dollar_1",
              text:
                "You only pay the leads you receive, we take care of everything else",
            },
          ].map((val, i) => (
            <Box
              key={i}
              flexShrink={0}
              width={["100%", "33.3%"]}
              sx={{
                mt: [i === 0 ? 0 : 7, 0],
                px: [4, 5, 7],
                textAlign: ["center"],
              }}
            >
              <Image
                src={require(`../../assets/images/${val.image}.png`)}
                alt=""
              />

              <Text mt={3} fontSize="19px" fontWeight="700">
                {val.text}
              </Text>
            </Box>
          ))}
        </Flex>
      </Container>

      <iframe
        ref={chatbot}
        title="chatbot"
        src="https://chats.spinup.media/c/site-contact-us"
        style={{
          width: "100%",
          height: "450px",
          paddingTop: 80,
          marginBottom: 40,
        }}
      />
    </Layout>
  )
}

export default Services
