import React from "react"
import { Box } from "rebass"

const Container = ({
  size = "large",
  maxWidth,
  backgroundColor = "transparent",
  px = [3, 5],
  py = [3, 5],
  pt,
  pb,
  sx = {},
  children,
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor,
        }}
      >
        <Box
          sx={{
            margin: `0 auto`,
            maxWidth: maxWidth || (size === "large" ? 1280 : 960),
            px,
            py,
            pt,
            pb,
            ...sx,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  )
}

export default Container
